<template>
  <div style="width: 100%;">
    <v-container class="mainContainer" fluid>
      <v-row>
        <v-col class='col-md-3 d-flex flex-column align-start justify-start'>
          <div class="d-flex flex-row align-center">
            <v-btn fab x-small @click="()=>this.$router.go(-1)">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <h1 class="mx-1">Order #{{this.$route.params.id}}</h1>
            <span v-if="!loader">
              <span v-if="invoice.status===-1" class="pa-1 v-btn warning" style="font-size: 12px">{{parseOrderStatus(invoice.status)}}</span>
              <span v-if="invoice.status===100" class="pa-1 v-btn red" style="font-size: 12px">Draft (Unsaved)</span>
              <span v-if="invoice.status===0" class="pa-1 v-btn orange" style="font-size: 12px">{{parseOrderStatus(invoice.status)}}</span>
              <span v-if="invoice.status===1" class="pa-1 v-btn success" style="font-size: 12px">{{parseOrderStatus(invoice.status)}}</span>
              <span v-if="invoice.status===2" class="pa-1 v-btn success" style="font-size: 12px">{{parseOrderStatus(invoice.status)}}</span>
              <span v-if="invoice.status===3" class="pa-1 v-btn success" style="font-size: 12px">{{parseOrderStatus(invoice.status)}}</span>
              <span v-if="invoice.status===4" class="pa-1 v-btn success" style="font-size: 12px">{{parseOrderStatus(invoice.status)}}</span>
              <span v-if="invoice.status===5" class="pa-1 v-btn success" style="font-size: 12px">{{parseOrderStatus(invoice.status)}}</span>
              <span v-if="invoice.status===6" class="pa-1 v-btn success" style="font-size: 12px">{{parseOrderStatus(invoice.status)}}</span>
            </span>
            <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
            ></v-progress-circular>
            <v-progress-circular
              indeterminate
              color="green"
              v-if="initialLoader"
            ></v-progress-circular>
            <v-icon v-if="this.syncStatus===0" class="ml-2" color="warning">mdi-cloud-refresh</v-icon>
            <v-icon v-if="this.syncStatus===1" class="ml-2" color="success">mdi-cloud-check</v-icon>
            <v-icon v-if="this.syncStatus===2" class="ml-2" color="error">mdi-cloud-alert</v-icon>
          </div>
          <div v-if="!initialLoader" outlined class="d-flex flex-column pa-1">
            <span class="d-flex flex-row flex-wrap">
              <v-btn v-if="getGlobalValue('PRINT_SYSTEM_ALLOW_CLOUD_PRINT')==='true'" :disabled="this.invoice.status<1" class="ml-2 mb-2" fab x-small color="info" @click="openCloudPrintDialog()"><v-icon>mdi-cloud-print</v-icon></v-btn>
              <v-btn v-if="(getGlobalValue('PRINT_SYSTEM_ALLOW_DIRECT_PRINT')==='true')&&(getGlobalValue('PRINT_SYSTEM_ALLOW_BIG_INVOICES')==='true')" :disabled="this.invoice.status<1" class="ml-2 mb-2"  small color="info" :loading="directBigPrintLoading" @click="directPrint({id:$route.params.id})">PRINT BIG</v-btn>
              <v-btn v-if="(getGlobalValue('PRINT_SYSTEM_ALLOW_DIRECT_PRINT')==='true')&&(getGlobalValue('PRINT_SYSTEM_ALLOW_SMALL_INVOICES')==='true')" :disabled="this.invoice.status<1" class="ml-2 mb-2"  small color="info" :loading="directSmallPrintLoading" @click="directPrint({id:$route.params.id})">PRINT SMALL</v-btn>
              <v-btn :disabled="!this.invoiceOK() || this.invoice.status !== 0"  class="ml-2" dense color="info" small @click="attemptConfirmSeal">Seal</v-btn>
              <!-- <v-btn :loading="printPreviewDialog.loading"  :disabled="this.invoice.status<1" class="ml-2" fab x-small color="info" @click="printPreview('invoice', {id:$route.params.id})"><v-icon>mdi-printer-eye</v-icon></v-btn> -->
              <!-- <authorizer
                v-if="isAllowed('order', 'seal')" 
                v-bind:buttontext="'Seal'" 
                v-bind:size="'small'"
                :disabled="!this.invoiceOK() || this.invoice.status !== 0" 
                v-on:response="authAttempt($event,'seal')"
              /> -->
              <authorizer 
                v-if="isAllowed('order', 'voidInvoice')" 
                v-bind:buttontext="'Void'" 
                v-bind:size="'small'"
                :disabled="this.invoice.status===-1 || this.invoice.status > 5" 
                style="margin-bottom: 10px;" v-bind:fabicon="null" v-bind:color="'warning'" 
                v-on:response="authAttempt($event,'void')"
              />
            </span>
          </div>
        </v-col>
        <v-col class="col-md-9">
          <v-card outlined>
            <v-container fluid>
              <v-row>
                <v-col class="d-flex flex-column">                
                  <span v-if="this.invoice && this.invoice.status ===-1"><b>Voided By: </b>{{this.invoice.voidedBy?this.lookupUsername(this.invoice.voidedBy) + ' at '+ this.formatDate(this.invoice.voidedAt):"Not Sealed"}}</span>
                  <span v-if="this.invoice && this.invoice.createdBy" style="font-size: 10px;"> Created By: {{this.lookupUsername(this.invoice.createdBy)}}</span>
                  <span v-if="this.invoice.sealedBy && this.invoice.sealedAt"><b>Sealed by: </b>{{this.lookupUsername(this.invoice.sealedBy) + ' at '+ this.formatDate(this.invoice.sealedAt)}}</span>
                </v-col>
                <v-col v-if="getGlobalValue('VEC_INCLUDES_AR')==='true'">
                  <span v-if="this.invoice.csrUser">
                    <span class="mr-3 d-flex flex-row">
                      <h3 style="text-align: left;">CSR</h3>
                      <v-btn :disabled="this.modifyDisabledCriteria" v-if="!this.modifyDisabledCriteria" @click="usersDialog=true" style="margin-left: 10px;" fab color="info" x-small><v-icon>mdi-account-search</v-icon></v-btn>
                    </span>
                    <span class="d-flex flex-row align-center">
                      <b>{{this.invoice.csrUser.firstName}} {{this.invoice.csrUser.lastName}}</b>
                      <v-btn :disabled="this.modifyDisabledCriteria" v-if="!this.modifyDisabledCriteria && this.invoice.csrUser" @click="removeCsrUser" style="margin-left: 10px;" fab color="error" x-small><v-icon>mdi-close</v-icon></v-btn>
                    </span>
                    <span class="mt-3 mb-3">
                        <span>Commission Value: {{this.formatPrice((invoice.metadata.commissionRate/100)*invoice.metadata.grandTotal)}}</span>
                    </span>
                    <span class="d-flex flex-row">
                      <v-text-field label="Rate %" type="number" dense outlined v-model="invoice.metadata.commissionRate" @change="updateInvoiceCommissionRate"/>
                      <v-text-field class="ml-2" label="Date Paid " type="number" dense outlined v-model="invoice.metadata.csrPaidAt" @change="updateInvoiceDatePaid"/>
                    </span>
                    
                  </span>
                  <span v-else>
                    <span class="mr-3 d-flex flex-row">
                      <h3 style="text-align: left;">Choose CSR</h3>
                      <v-btn :disabled="this.modifyDisabledCriteria" v-if="!this.modifyDisabledCriteria" @click="usersDialog=true" style="margin-left: 10px;" fab color="info" x-small><v-icon>mdi-account-search</v-icon></v-btn>
                    </span>
                  </span>
                </v-col>
                <v-col>
                  <span class="mr-3 d-flex flex-row ">
                    <h3 style="text-align: left;">Customer</h3>
                    <v-btn :disabled="this.modifyDisabledCriteria" class="ml-2" v-if="!this.modifyDisabledCriteria" @click="createCustomerDialog=true" fab color="info" x-small><v-icon>mdi-plus</v-icon></v-btn>
                    <!-- <v-btn :disabled="this.modifyDisabledCriteria" v-if="!this.modifyDisabledCriteria" @click="customersDialog=true" style="margin-left: 10px;" fab color="info" x-small><v-icon>mdi-account-search</v-icon></v-btn> -->
                  </span>
                  <span v-if="invoice.customerId && selectedCustomer" class="d-flex flex-row justify-start align-center">
                    <span class="d-flex flex-column mr-2">
                      <h4 style="text-align: left;">{{selectedCustomer.name}}</h4>
                      <span v-if="selectedCustomer.phone"><b>Phone: </b>{{selectedCustomer.phone}}</span>
                    </span>
                    <v-btn :disabled="this.modifyDisabledCriteria" @click="removeCustomerFromInvoice()" fab x-small color="error"><v-icon>mdi-close</v-icon></v-btn>
                  </span>
                </v-col>
                <v-col class="d-flex flex-column">
                  <span class="d-flex flex-column align-start ">
                    <h3>Payment Method </h3>
                    <span>
                      <v-radio-group dense class="mt-0" :disabled="this.modifyDisabledCriteria" v-model="invoice.metadata.paymentMethod" @change="this.updateInvoice">
                        <v-radio
                            v-for="x in this.paymentMethods"
                            :key="x.id"
                            :label="x.name"
                            :value="x.id"
                        ></v-radio>
                      </v-radio-group>
                    </span>
                    <v-text-field
                      outlined
                      dense
                      type="number"
                      v-if="this.getGlobalValue('VEC_ORDER_INCLUDES_HP_DOWNPAYMENT_VALUE')&&invoice.metadata.paymentMethod===2"
                      name="name"
                      label="HP Downpayment"
                      @change="updateInvoice()"
                      v-model="invoice.metadata.hpDownpayment"
                    ></v-text-field>
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="!initialLoader">
        <v-col class="col-md-3">
          <span class="d-flex flex-column" v-if="!this.modifyDisabledCriteria">
            <h2>Add Item</h2>
              <v-card outlined>
                <v-card-text>
                  <span class="d-flex flex-row align-center">
                    <!-- <h2>Add Product</h2> -->
                    <!-- <v-btn class="ml-2" :loading="listeningForBarcodeScan" @click="listenForBarcodes()" fab small color="error" ><v-icon>mdi-barcode-scan</v-icon></v-btn> -->
                  </span>
                  <span class="d-flex flex-row align-center justify-center">
                    <v-text-field style="width: 100%;" :disabled="this.modifyDisabledCriteria" @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc" v-model="productSearchTerm" @change="searchProduct()" :loading="loadingSearchProduct" label="Search Product" dense outlined></v-text-field>
                    <v-text-field :disabled="this.modifyDisabledCriteria" @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc" ref="barcodeTerm" style="opacity:0; width: 1px;" v-model="productSearchBarcode" @change="barcodeSearchProduct()" dense outlined></v-text-field>
                  </span>
                  <!-- <span class="d-flex flex-column">
                    <span>Prepend the following for a refined search</span>
                    <span>id:[ID Number]</span>
                    <span>b:[Brand Name]</span>
                    <span>s:[Supplier Name]</span>
                  </span> -->
                  <p v-if="noSearchResults">No search results were found.</p>
                  <span v-if="productSearchResults&&productSearchResults.length>0">
                    <span class="d-flex flex-row justify-center">
                      <b>Search Results</b>
                      <v-btn x-small color="info" style="margin-left: 10px;" @click="clearProductSearchResults">Clear</v-btn>
                    </span>
                    <div v-for="item in productSearchResults" :key="item.id">
                      <div class="d-flex flex-column justify-space-between" style="align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px;">
                        <span class="d-flex flex-row justify-space-between">
                          <b style="text-align: left;">{{item.Brand?`${item.Brand.name} `:''}} {{item.name}}</b>
                          <v-btn :disabled="!canProductSell(item)" x-small fab color="success" @click="addProductToInvoice(item)"><v-icon>mdi-plus</v-icon></v-btn>
                        </span>
                        <span class="d-flex flex-column align-start">
                          <span><b>SP: ${{item.regularPrice}}</b>, <span v-if="isAllowed('product', 'viewCostPrice')">PP: ${{item.salePrice}}</span></span>
                          <span><b>QTY: {{item.ProductLocationJoins.find(x => x.userBranch===true)?item.ProductLocationJoins.find(x => x.userBranch===true).available:0}}</b><span v-if="getGlobalValue('showBranchQuantities')==='true'">[{{item.ProductLocationJoins.reduce((total, curr) => total+parseInt(curr.available), 0)}}]</span></span>
                          <!-- <p>{{item}}</p> -->
                          <span>ID: {{item.id}}, SKU:{{item.sku}}</span>
                        </span>
                      </div>
                    </div>
                  </span>
                </v-card-text>
              </v-card>
          </span>
          <span v-else>
            <span class="d-flex flex-column">
              <span class="d-flex flex-row">
                <h2>Payments</h2>
                <v-btn :disabled="this.orderBalance>=0" class="ml-3" x-small fab color="success" @click="openAddPayment"><v-icon>mdi-plus</v-icon></v-btn>
              </span>
              <v-card outlined>
                <v-card-text>
                  <h3>Balance: {{this.formatPrice(this.orderBalance)}}</h3>
                  <v-data-table
                      :headers="paymentTable.headers"
                      :items="paymentTable.items"
                      :items-per-page="-1"
                      class="elevation-0"
                      @click:row="paymentTableRowClick"
                  >
                    <template v-slot:item.createdAt="{ item }">
                      <span>{{formatDate(item.createdAt) }}</span>
                    </template>
                    <template v-slot:item.amount="{ item }">
                      <span>{{formatPrice(item.amount) }}</span>
                    </template>
                  </v-data-table>
                </v-card-text>

              </v-card>
            </span>  
          </span>
        </v-col>       
        <v-col class="col-md-7">
          <v-row>
            <v-col>
              <span class="d-flex flex-row align-center">
                <h2>Order Items ({{invoice.OrderLineItems?invoice.OrderLineItems.reduce((acc,x)=>acc+parseInt(x.quantity),0):0}} units)</h2>
                <v-btn :disabled="modifyDisabledCriteria" v-if="getGlobalValue('VEC_INCLUDES_CREATE_ADHOC_PRODUCT_IN_ORDER')==='true'" color="info" class="ml-2" fab x-small @click="addAdhocLineItem"><v-icon>mdi-plus</v-icon></v-btn>
              </span>
              <v-card outlined>
                <v-container>
                  <v-row>
                    <v-col v-if="invoice">
                      <v-simple-table id="orderItemsTable">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>QTY</th>
                              <th>Name</th>
                              <th>SKU</th>
                              <th v-if="getGlobalValue('VEC_INCLUDES_ORDER_LINE_ITEM_DISCOUNT')==='true'">Discount</th>
                              <th>Unit Price</th>
                              <th>Total</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, i) in invoice.OrderLineItems" :key="i">
                              <td>
                                <v-text-field :disabled="modifyDisabledCriteria" type="number" min="1" dense v-model="item.quantity" @change="updateLineItem(item)" :max="item.max" style="width: 60px; margin-bottom: -27px;" outlined/>
                              </td>
                              <td style="text-align: left;">
                                <span v-if="!item.pending">{{item.productName}}</span>
                                <v-text-field style="width: 250px; margin-bottom: -26px;" outlined dense v-if="item.pending" v-model="item.productName"/>
                              </td>
                              <td style="">
                                <span v-if="!item.pending">{{item.sku}}</span>
                                <v-text-field style="width: 150px; margin-bottom: -26px;" outlined dense v-if="item.pending" v-model="item.sku"/>
                              </td>
                              <td v-if="getGlobalValue('VEC_INCLUDES_ORDER_LINE_ITEM_DISCOUNT')==='true'">
                                <span class="d-flex flex-row" v-if="!item.pending">
                                  <v-btn :disabled="modifyDisabledCriteria" @click="updateLineItemDiscountType(i)" text x-small style="font-size: 20px; border: 1px solid #ddd; height: 40px; border-top-right-radius: 0; border-bottom-right-radius: 0;" class="pa-0 ma-0" elevation="0">{{item.discountType===0?'%':'$'}}</v-btn>
                                  <v-text-field :disabled="modifyDisabledCriteria" min="0" style="border-bottom-left-radius: 0; border-top-left-radius: 0; width: 70px; margin-bottom: -26px;" type="number" dense @change="updateLineItem(item)" v-model="item.discountValue" outlined/>
                                </span>
                              </td>
                              <td v-if="getGlobalValue('VEC_INCLUDES_ORDER_LINE_ITEM_COMMISSION')==='true'">
                                <span class="d-flex flex-row" v-if="!item.pending">
                                  <v-btn :disabled="modifyDisabledCriteria" @click="updateLineItemCommissionType(i)" text x-small style="font-size: 20px; border: 1px solid #ddd; height: 40px; border-top-right-radius: 0; border-bottom-right-radius: 0;" class="pa-0 ma-0" elevation="0">{{item.metadata.commission.type===0?'%':'$'}}</v-btn>
                                  <v-text-field :disabled="modifyDisabledCriteria" min="0" style="border-bottom-left-radius: 0; border-top-left-radius: 0; width: 70px; margin-bottom: -26px;" type="number" dense v-model="item.metadata.commission.value" outlined/>
                                </span>
                              </td>
                              <td>
                                <span v-if="!item.pending && invoice.metadata.paymentMethod!=2">{{formatPrice(item.unitPrice)}}</span>
                                <v-text-field v-else-if="item.pending" style="width: 100px; margin-bottom: -26px;" min="0" v-model="item.unitPrice" type="number" dense outlined></v-text-field>
                                <span v-else>
                                  <v-text-field :disabled="modifyDisabledCriteria" style="margin-bottom: -26px;" min="0" v-model="item.unitPrice" type="number" dense outlined></v-text-field>
                                </span>
                              </td>
                              <td>
                                <span>{{formatPrice(item.unitPrice*item.quantity)}}</span>
                              </td>
                              <td>
                                <v-btn fab x-small class="mr-1" v-if="item.productId==='adhoc'" :disabled="!item.productName || item.unitPrice==0" :loading="item.loading" @click="completeAdhocItem(i)" color="success"><v-icon>mdi-check</v-icon></v-btn>
                                <v-btn :loading="productImageDialog.loading" class="mr-1" v-if="item.productId!=='adhoc' && getGlobalValue('VEC_INCLUDES_ORDER_LINE_PRODUCT_IMAGE_PREVIEW')==='true'" @click="showLineItemProductImage(item)" x-small fab color="info"><v-icon>mdi-image</v-icon></v-btn>
                                <v-btn class="mr-1" :disabled="modifyDisabledCriteria" @click="removeProduct(item)" x-small fab color="error"><v-icon>mdi-close</v-icon></v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <div id="subtotals" class="d-flex flex-row justify-end" style="width: 100%; margin-top: 10px;">
                        <div class="d-flex flex-column align-end">
                          <b>Subtotal</b>
                          <!-- <b>Tax ({{this.taxRate}}%)</b> -->
                          <!-- <b>Total</b> -->
                        </div>
                        <div style="width: 120px; padding-right:20px;" class="d-flex flex-column align-end">
                    <span class="d-flex flex-column align-end">
                      <div>{{this.formatPrice(computedSubtotal)}}</div>
                      <!-- <div>{{this.formatPrice(this.invoice.subtotal*(taxRate/100)||0)}}</div> -->
                      <!-- <div>{{this.formatPrice(this.invoice.metadata.grandTotal)}}</div> -->
                    </span>
                        </div>
                      </div>
                      <!-- <div v-for="item in invoice.orderItems" :key="item.id">
                        <div class="d-flex flex-row justify-space-between" style="align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px;">
                          <span class="d-flex flex-column align-start">
                            <b style="text-align: left;">{{item.totalQuantity}} x {{item.name}}</b>
                            <b style="text-align: left;">${{item.regularPrice}}</b>
                          </span>
                        </div>
                      </div> -->
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex">
                      <v-card v-if="invoice.OrderLineItems && invoice.OrderLineItems.length>0" outlined class="flex-grow-1">
                        <v-container>
                          <v-row>
                            <v-col>
                              <h2>Discounts</h2>
                              <span class="d-flex flex-column">
                                <span class="d-flex flex-row align-center" style="height: 100px;">
                                  <v-text-field clearable dense :disabled="this.modifyDisabledCriteria" :rules="discountRules" v-model="invoice.discountAmount" type="number" @change="updateOrderDiscount()" label="Discount Amount" outlined/>
                                </span>
                              </span>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                    <v-col></v-col>
                    <v-col cols="5" class="d-flex">
                      <v-card v-if="invoice" outlined class="flex-grow-1">
                        <v-container>
                          <v-row>
                            <v-col>
                              <h2>Verify Totals</h2>
                              <span class="d-flex flex-row justify-end">
                                <span class="d-flex flex-column text-right pr-2">
                                  <b>Subtotal:</b>
                                  <b>-Discounts:</b>
                                  <b v-if="this.invoice.metadata.creditNotes&&this.invoice.metadata.creditNotes.length>0">-Credit Notes:</b>
                                  <b>+Delivery:</b>
                                  <h3>Grand Total:</h3>
                                </span>
                                <span class="d-flex flex-column text-right">
                                  <span>{{formatPrice(this.computedSubtotal)}}</span>
                                  <span>{{formatPrice((discountApplied) ? invoice.discountAmount : 0)}}</span>
                                  <span v-if="invoice.metadata.creditNotes&&invoice.metadata.creditNotes.length>0">{{formatPrice(invoice.metadata.creditNotesTotal)}}</span>
                                  <span>{{formatPrice(invoice.deliveryInfo.cost ? invoice.deliveryInfo.cost : 0)}}</span>
                                  <h3>{{formatPrice(this.computedGrandTotal)}}</h3>
                                </span>
                              </span>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <!-- <v-btn outlined v-if="this.invoice.authorizerId"><v-icon color="success">mdi-check</v-icon> Sealed by {{this.lookupUsername(this.invoice.authorizerId)}}</v-btn> -->
              <!-- <v-btn style="margin-top: 10px;" :disabled="!invoiceOK" @click="signAndSave" :loading="saveInvoiceLoader" color='success'>Create Invoice</v-btn> -->
              <!-- <v-btn color="success" @click="openSIDAuthorizer({type: 'scanSign'})">Sign Thing</v-btn>
              <v-btn color="success" @click="openSIDAuthorizer({type: 'scanAuthorize', resource: 'order', requiredPerm: 'authorizeDiscounts'})">Authorize Thing</v-btn> -->
            </v-col>
          </v-row>
        </v-col>
        <v-col class="col-md-2">
          <v-row>
          </v-row>
          <v-row>
            <v-col v-if="invoice.customerId">
              <span>
                <h2>Delivery</h2>
              </span>
              <v-card outlined>
                <v-container>
                  <v-row>
                    <v-col class="d-flex flex-row">
                      <h3>Delivery Information</h3>
                      <v-btn class="ml-3" v-if="!this.showDeliveryInfo" @click="showDeliveryInfo=true" x-small fab color="success"><v-icon>mdi-chevron-down</v-icon></v-btn>
                      <v-btn class="ml-3" v-else x-small fab color="error" @click="showDeliveryInfo=false"><v-icon>mdi-chevron-up</v-icon></v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="this.showDeliveryInfo">
                    <v-col class="d-flex flex-column">
                      <span>
                        <v-radio-group :disabled="this.modifyDisabledCriteria" @change="this.updateInvoice" v-model="invoice.deliveryInfo.deliveryType" row>
                          <v-radio
                              label="Delivery"
                              value="delivery"
                          ></v-radio>
                          <v-radio
                              label="Pick Up"
                              value="pickup"
                          ></v-radio>
                        </v-radio-group>
                      </span>
                      <span v-if="this.invoice.deliveryInfo.deliveryType == 'delivery'">
                        <span class="d-flex flex-column">
                          <span class="d-flex flex-row justify-center mb-1">
                            <h3>Delivery Address</h3>
                            <v-btn :disabled="this.modifyDisabledCriteria" v-if="selectedCustomer" color="info" class="ml-2" x-small fab @click="addressDialog=true"><v-icon>mdi-chevron-right</v-icon></v-btn>
                          </span>
                          <span class="d-flex flex-column">
                            <v-text-field dense class="mx-1" :disabled="this.modifyDisabledCriteria" v-model="invoice.deliveryInfo.address.line1" @change="checkCustomAddress" label="Address Line 1" outlined></v-text-field>
                            <v-text-field dense class="mx-1" :disabled="this.modifyDisabledCriteria" v-model="invoice.deliveryInfo.address.line2" @change="checkCustomAddress" label="Address Line 2" outlined></v-text-field>
                            <v-text-field dense class="mx-1" :disabled="this.modifyDisabledCriteria" v-model="invoice.deliveryInfo.address.city" @change="checkCustomAddress" label="Town/City" outlined></v-text-field>
                            <v-text-field dense class="mx-1" :disabled="this.modifyDisabledCriteria" v-model="invoice.deliveryInfo.address.country" @change="checkCustomAddress" label="Country" outlined></v-text-field>
                          </span>
                          <v-btn style="margin-top: -20px;" v-if="invoice.deliveryInfo.customAddress" color="info" @click="saveCustomAddressDialog=true" small>Save New Address</v-btn>
                          <h3 class="mt-2">Delivery Charges</h3>
                          <v-text-field :disabled="this.modifyDisabledCriteria" v-model="invoice.deliveryInfo.cost" @change="this.updateInvoice" min="0" label="Delivery Cost" type="number" outlined></v-text-field>
                        </span>
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <v-card outlined v-if="invoice.status>=1 && invoice.deliveryInfo.deliveryType==='delivery'">
                <v-container>
                  <v-row>
                    <v-col class="d-flex flex-row">
                      <h3>Delivery Schedule</h3>
                      <v-btn :disabled="invoice.status < 1" x-small fab class="ml-3" color="info" @click="createDeliveryA()"><v-icon>mdi-plus</v-icon></v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-data-table
                        v-if="!initialLoader"
                        :headers="deliverySchedule.tableHeaders"
                        :items="deliverySchedule.data|| []"
                        :items-per-page="-1"
                        @click:row="rowClick"
                      >
                        <template v-slot:item.dueDate="{ item }">
                          <span>{{formatDate(item.dueDate) }}</span>
                        </template>
                        <template v-slot:item.status="{ item }">
                          <span>{{parseDeliveryStatus(item.status) }}</span>
                        </template>
                        <template v-slot:item.numUnits="{ item }">
                          <span>{{ item.deliveryItems?item.deliveryItems.reduce((acc,x)=>acc+x.deliveryQty,0):0 }}</span>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="productImageDialog.isOpen" width="unset">
      <span class="d-flex flex-row align-center justify-center">
        <img :src="productImageDialog.source" />
      </span>
    </v-dialog>

    <v-dialog
      v-model="printPreviewDialog.isOpen"
      width="11in"
      scrollable
    >
      <v-card v-if="printPreviewDialog.data">
        <v-card-title class="text-h5">Preview</v-card-title>
        <v-card-text>
          <div id="orderPreview" v-html="printPreviewDialog.data.job.htmlToPrint"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closePrintPreviewDialog">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="createReturnDialog.isOpen" width="500">
      <v-card>
        <v-card-title class="text-h5">Confirm Create Return</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="createReturnDialog.isOpen = false">Close</v-btn>
          <v-btn color="success" text @click="createNewReturn()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmSealDialog.isOpen" width="500">
      <v-card class="d-flex flex-column align-center justify-center">
        <v-card-title class="text-h5">Confirm Seal Order</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="confirmSealDialog.isOpen = false">Close</v-btn>
          <v-btn color="success" :loading="confirmSealDialog.loading" @click="sealOrder()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="createCustomerDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Create Customer
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-text-field :loading="findCustomerByPhoneLoader" v-model="newCustomer.phone" @keyup="findCustomerByPhone" label="Phone" outlined></v-text-field>
          <v-chip style="margin-bottom: 20px; margin-right: 5px;" v-for="suggestion in newCustomer.suggestedMatches" :key="suggestion.id" @click="setCustomerAndClose(suggestion)">
            <span>{{suggestion.name}} - {{suggestion.phone}}</span>
          </v-chip>
          <v-text-field v-model="newCustomer.name" label="Name" outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!newCustomer.name" class="primary" text @click="createCustomer">
            Create
            <v-progress-circular
                indeterminate
                color="green"
                v-if="customerLoading"
                style="margin-left: 10px;"
            ></v-progress-circular>
          </v-btn>
          <v-btn class="error" text @click="closeCreateCustomer">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="customersDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Customers
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-text-field v-model="customerSearchTerm" @change="searchCustomer" :loading="searchResultsLoader" label="Search Customers" outlined></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-card :key="i" v-for="(customer, i) in customerSearchResults" class="d-flex flex-row align-center">
            <span class="d-flex flex-column justify-start" style="text-align: left; padding: 20px;">
              <h3>{{customer.name}}</h3>
              <p v-if="customer.email">{{customer.email}}</p>
              <p v-if="customer.phone">{{customer.phone}}</p>
            </span>
            <v-btn @click="setCustomer(customer)" fab x-small color="success"><v-icon>mdi-plus</v-icon></v-btn>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeCustomersDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="usersDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Users
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-text-field v-model="userSearchTerm" @change="searchUser" :loading="searchResultsLoader" label="Search Users" outlined></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-card :key="i" v-for="(user, i) in userSearchResults" class="d-flex flex-row align-center">
            <span class="d-flex flex-column justify-start" style="text-align: left; padding: 20px;">
              <h3>{{user.firstName}} {{user.lastName}}</h3>
              <p v-if="user.email">{{user.email}}</p>
              <p v-if="user.phone">{{user.phone}}</p>
            </span>
            <v-btn @click="setUser(user)" fab x-small color="success"><v-icon>mdi-plus</v-icon></v-btn>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeUsersDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="addressDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Customer Addresses
        </v-card-title>
        <v-card-text v-if="selectedCustomer && selectedCustomer.addresses && selectedCustomer.addresses.length>0">
          <v-card :key="i" v-for="(addr, i) in selectedCustomer.addresses" class="d-flex flex-row pa-2 justify-space-between mb-2" outlined>
            <span class="d-flex flex-column text-left">
              <h3 v-if="addr.name">{{addr.name}}</h3>
              <span v-if="addr.line1"><b>Address: </b>{{addr.line1}}{{addr.line2?", "+addr.line2:""}}</span>
              <span v-if="addr.city"><b>Town/City: </b>{{addr.city}}</span>
              <span v-if="addr.country"><b>Country: </b>{{addr.country}}</span>
            </span>
            <v-btn @click="setAddress(addr)" fab x-small color="success"><v-icon>mdi-plus</v-icon></v-btn>
          </v-card>
        </v-card-text>
        <v-card-text v-else>
          <span>This customer has no addresses.</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeAddressDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="saveCustomAddressDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Save Custom Address
        </v-card-title>
        <v-card-text>
          <v-text-field type="text" dense outlined label="Name of Address" v-model="customAddressName"/>
          <div class="d-flex flex-column">
            <span v-if="invoice.deliveryInfo.address.line1">{{invoice.deliveryInfo.address.line1}}<span v-if="invoice.deliveryInfo.address.line2">, {{invoice.deliveryInfo.address.line2}}</span></span>
            <span v-if="invoice.deliveryInfo.address.city">{{invoice.deliveryInfo.address.city}}<span v-if="invoice.deliveryInfo.address.country">, {{invoice.deliveryInfo.address.country}}</span></span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeCustomAddressDialog">
            Cancel
          </v-btn>
          <v-btn class="success" text @click="saveCustomAddress">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="createDeliveryDialog.isOpen" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          Create New Delivery
        </v-card-title>
        <v-card-actions>
          <v-btn small text color="error" @click="cancelCreateDelivery()">
            Cancel
          </v-btn>
          <v-btn small color="success" @click="createDeliveryB">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addPaymentDialog.isOpen" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          Add Payment
        </v-card-title>
        <v-card-text>
          <v-radio-group @change="runPaymentDialogValidations" v-model="addPaymentDialog.selectedType" row>
            <v-radio
              v-for="n in this.paymentTypes"
              :key="n.id"
              :label="n.name"
              :value="n.id"
            ></v-radio>
          </v-radio-group>
          <v-btn v-if="this.orderBalance" :disabled="Math.abs(this.orderBalance)==addPaymentDialog.paymentAmount||this.orderBalance>0" @click="()=>{addPaymentDialog.paymentAmount = Math.abs(this.orderBalance); runPaymentDialogValidations()}" color="info" block>Apply Balance: {{this.formatPrice(Math.abs(this.orderBalance))}}</v-btn>
          <v-text-field @change="runPaymentDialogValidations" style="margin-top: 10px;" dense v-model="addPaymentDialog.paymentAmount" type="number" label="Amount Tendered" outlined></v-text-field>
          <v-text-field v-if="this.lookupPaymentType(addPaymentDialog.selectedType)=='Cheque'" style="margin-top: 10px;" dense v-model="addPaymentDialog.chequeNumber" type="number" label="Cheque Number" outlined></v-text-field>
          <v-select
            v-if="this.lookupPaymentType(addPaymentDialog.selectedType)=='Credit Note'"
            label="Credit Note"
            :items="addPaymentDialog.creditNotesAvailable"
            item-text="name"
            item-value="id"
            v-model="addPaymentDialog.creditNotes"
            @change="runPaymentDialogValidations"
            outlined
            multiple
            chips
          ></v-select>
          <p v-if="addPaymentDialog.cnMessage">{{addPaymentDialog.cnMessage}}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn small text color="error" @click="closeAddPaymentDialog()">
            Cancel
          </v-btn>
          <v-btn :disabled="addPaymentDialogDisabledCriteria" small color="success" @click="addPayment">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewPaymentDialog.isOpen" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          View Payment
        </v-card-title>
        <v-card-text>
          <p>Date Received: {{formatDate(viewPaymentDialog.paymentToDisplay.createdAt)}}</p>
          <p>Received By: {{lookupUsername(viewPaymentDialog.paymentToDisplay.receivedBy)}}</p>
          <p>Amount: {{formatPrice(viewPaymentDialog.paymentToDisplay.amount)}}</p>
          <p>Payment Type: {{lookupPaymentType(viewPaymentDialog.paymentToDisplay.paymentType)}}</p>
          <p v-if="lookupPaymentType(viewPaymentDialog.paymentToDisplay.paymentType)=='Cheque'">Cheque Number: {{viewPaymentDialog.paymentToDisplay.metadata.chequeNumber}}</p>
          <span v-if="lookupPaymentType(viewPaymentDialog.paymentToDisplay.paymentType)=='Credit Note'">
            <span>Credit Notes Used: </span>
            <span v-for="cn in viewPaymentDialog.paymentToDisplay.metadata.creditNotes" :key="cn.id">
              {{cn}}
            </span>
          </span>
          <p v-if="viewPaymentDialog.paymentToDisplay.cnMessage">{{viewPaymentDialog.paymentToDisplay.cnMessage}}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn small text color="error" @click="closeViewPaymentDialog">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<!--    <v-dialog scrollable v-model="creditNotesDialog" width="500">-->
<!--      <v-card>-->
<!--        <v-card-title class="text-h5">-->
<!--          Credit Notes-->
<!--        </v-card-title>-->
<!--        <v-card-text>-->
<!--          <v-card :key="i" v-for="(note, i) in selectedCustomer.CreditNotes" class="d-flex flex-row align-center">-->
<!--            <span class="d-flex flex-column justify-start" style="text-align: left; padding: 20px;">-->
<!--              <h3>Credit Note #{{note.id}}</h3>-->
<!--              <p>Amount: {{formatPrice(note.amount)}}</p>-->
<!--            </span>-->
<!--            <v-btn @click="addCreditNote(note)" fab x-small color="success"><v-icon>mdi-plus</v-icon></v-btn>-->
<!--          </v-card>-->
<!--        </v-card-text>-->
<!--        <v-card-actions>-->
<!--          <v-spacer></v-spacer>-->
<!--          <v-btn class="error" text @click="closeCreditNotesDialog">-->
<!--            Cancel-->
<!--          </v-btn>-->
<!--        </v-card-actions>-->
<!--      </v-card>-->
<!--    </v-dialog>-->
    <!-- <canvas style="border: 1px solid black; background-color: white; cursor: crosshair;" @mousedown="mousedown" @mousemove="mousemove" @mouseup="sign = false" @mouseout="sign = false" /> -->
    <v-dialog
      v-model="printDialog.isOpen"
      width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Print
        </v-card-title>
        <v-card-text>
          <h3 style="margin: 0px;">Select item to print</h3>
          <v-radio-group v-model="printDialog.jobType" @change="filterPrinters" row mandatory>
            <v-radio
              label="Invoice Only"
              value="invoice"
            ></v-radio>
            <v-radio
              label="Delivery Note Only"
              value="deliveryNote"
            ></v-radio>
            <v-radio
              label="Job Ticket Only"
              value="jobTicket"
            ></v-radio>
            <v-radio
              label="Full Order Print"
              value="al"
              selected
            ></v-radio>
          </v-radio-group>
          <v-text-field v-if="printDialog.jobType=='deliveryNote'" style="margin-top: 10px;" dense v-model="printDialog.deliveryNote" type="number" label="Delivery Note To Print" outlined></v-text-field>
          <hr>
          <v-select
            class="mt-2"
            :items="filteredPrinters"
            item-text="name"
            item-value="id"
            label="Select a Printer"
            v-model="printDialog.printer"
            outlined
          ></v-select>
          <v-text-field style="margin-top: 10px;" dense v-model="printDialog.quantity" type="number" default="1" label="Quantity" outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closePrintDialog()">
            Cancel
          </v-btn>
          <authorizer 
            v-bind:buttontext="'Print'" 
            v-bind:small="true" 
            v-on:response="attemptPrint($event)"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="chordingDialog.isOpen" width="600">
        <v-card>
          <v-card-title class="d-flex flex-row justify-space-between">
            <h1>Let's go!</h1>
            <span>
              <span v-for="(key,index) in chordingDialog.chord" :key="index">
                  <v-chip x-small dense>{{key.toUpperCase()}}</v-chip>
              </span>
            </span>
          </v-card-title>
          <v-card-text class="d-flex flex-column">
            <span class="d-flex flex-row">
              <span class="d-flex flex-row" v-if="chordingDialog.chord.length<1">
                <div class="d-flex flex-column  justify-center px-2">
                  <v-chip class="mr-2" small color="info">N</v-chip>
                  <b>New Order</b>
                </div>
                <div class="d-flex flex-column px-2">
                  <v-chip class="mr-2" small color="info">A</v-chip>
                  <b>Add Product</b>
                </div>
                <div class="d-flex flex-column px-2">
                  <v-chip class="mr-2" small color="info">B</v-chip>
                  <b>Back to List</b>
                </div>
                <div class="d-flex flex-column px-2">
                  <v-chip class="mr-2" small color="info">C </v-chip>
                  <b>Customer Entry</b>
                </div>
                <div class="d-flex flex-column px-2">
                  <v-chip class="mr-2" small color="info">S</v-chip>
                  <b>Seal</b>
                </div>
                <div class="d-flex flex-column px-2">
                  <v-chip class="mr-2" small color="info">P</v-chip>
                  <b>Print</b>
                </div>
              </span>
              <span v-else>
                <span class="d-flex flex-row" v-if="chordingDialog.chord[0] == 'o'">
                  <div class="d-flex flex-column  justify-center px-2">
                    <v-chip class="mr-2" small color="success">O</v-chip>
                    <b>Orders</b>
                  </div>
                  <div v-if="chordingDialog.chord[1]!='v'" class="d-flex flex-column  justify-center px-2">
                    <v-chip class="mr-2" small color="info">N</v-chip>
                    <b>New</b>
                  </div>
                  <div v-if="chordingDialog.chord.length<2||chordingDialog.chord[1]=='v'" class="d-flex flex-column  justify-center px-2">
                    <v-chip class="mr-2" small :color="chordingDialog.chord[1]=='v'?'success':'info'">V</v-chip>
                    <b>View</b>
                  </div>
                  <div v-if="chordingDialog.chord[1]!='v'" class="d-flex flex-column  justify-center px-2">
                    <v-chip class="mr-2" small color="info">L</v-chip>
                    <b>List</b>
                  </div>
                  <span v-if="chordingDialog.chord[1]=='v'">Enter the order ID and press ENTER</span>
                </span>
                <span class="d-flex flex-row align-center" v-if="chordingDialog.chord[0] == 's'">
                  <div class="d-flex flex-column  justify-center px-2">
                    <v-chip class="mr-2" small color="success">S</v-chip>
                    <b>Search</b>
                  </div>
                  <div v-if="chordingDialog.chord.length<2||chordingDialog.chord[1]=='p'" class="d-flex flex-column  justify-center px-2">
                    <v-chip class="mr-2" small :color="chordingDialog.chord[1]=='p'?'success':'info'">P</v-chip>
                    <b>Products</b>
                  </div>
                  <div v-if="chordingDialog.chord.length<2||chordingDialog.chord[1]=='c'" class="d-flex flex-column  justify-center px-2">
                    <v-chip class="mr-2" small :color="chordingDialog.chord[1]=='c'?'success':'info'">C</v-chip>
                    <b>Customers</b>
                  </div>
                  <span v-if="chordingDialog.chord[1] != null">Enter the search term.</span>
                </span>
              </span>
            </span>
            <span v-if="search.results.length>0">
              <span class="d-flex flex-row align-center mb-1">
                <h3>Results ({{search.results.length}})</h3>
              </span>
              <span v-if="search.type==='Products'">
                <span v-for="(product, i) in search.results" :key="i">
                  <v-card style="cursor: pointer;" outlined class="mb-1 pa-1" @click="$router.push(`/products/view/${product.id}`)">
                    <v-chip color="primary"><b>{{product.ProductLocationJoins.find(x => x.userBranch===true)?product.ProductLocationJoins.find(x => x.userBranch===true).available:0}}</b><span v-if="getGlobalValue('showBranchQuantities')==='true'">[{{product.ProductLocationJoins.reduce((total, curr) => total+parseInt(curr.available), 0)}}]</span></v-chip>
                    <span class="ml-2">ID:{{product.id}} | </span>
                    <span class="ml-2" style="font-size: 18px;"><b>{{product.Brand?'['+product.Brand.name+']':''}} {{product.name}}</b></span>
                    <span v-if="product.sku"> | {{product.sku}}</span>
                    <span style="float: right;">{{utils.formatCurrency(product.regularPrice)}}</span>
                  </v-card>
                </span>
              </span>
              <span v-if="search.type==='Customers'">
                <span v-for="(customer, i) in search.results" :key="i">
                  <v-card style="cursor: pointer;" outlined class="mb-1 pa-1" @click="$router.push(`/customers/view/${customer.id}`)">
                    <span style="border: 1px solid black; border-radius: 0;" class="pa-1">{{customer.id}}</span>
                    <span class="ml-2" style="font-size: 18px;"><b>{{customer.name}}</b></span>
                    <span v-if="customer.email"> | {{customer.email}}</span>
                    <span style="float: right;">{{customer.phone}}</span>
                  </v-card>
                </span>
              </span>
            </span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <span>Press <v-chip small dense>X</v-chip> to clear, <v-chip small dense>Backspace</v-chip> to go back one, or <v-chip small dense>/</v-chip> to cancel.</span>
          </v-card-actions>
        </v-card>
      </v-dialog>

    <v-dialog
      v-model="productScanDialog.isOpen"
      width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Product Scanned
        </v-card-title>
        <v-card-text>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="productScanDialog.loader"
              style="margin-left: 10px;"
          ></v-progress-circular>
          <!-- {{this.productScanDialog.item}} -->
          <div v-if="this.productScanDialog.item" class="d-flex flex-row justify-space-between" style="align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px;">
            <span class="d-flex flex-column align-start">
              <b style="text-align: left;">{{this.productScanDialog.item.name}}</b>
              <span>SP: ${{this.productScanDialog.item.regularPrice}}</span>
              <span v-if="isAllowed('product', 'viewCostPrice')">PP: ${{this.productScanDialog.item.salePrice}}</span>
            </span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeProductScanDialog()">
            Close
          </v-btn>
          <v-btn :disabled="this.modifyDisabledCriteria" text color="success" @click="()=>{addProductToInvoice(productScanDialog.item); closeProductScanDialog()}">Add To Order</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
#orderItemsTable th, #orderItemsTable td{
  text-align: center;
}
</style>
<script>
  import axios from 'axios';
  // import moment from 'moment';
  import _ from 'lodash'
  import {mapGetters, mapMutations} from 'vuex'
  import authorizer from '../../components/authorizer.vue'
  import scanSystem from "../../plugins/scanSystem"
  import utils  from "../../plugins/helpers"
  export default {
    components: {
      authorizer,
    },
    data () {
      return {
        utils: utils,
        ctx :  null,
        loader: false,
        initialLoader: false,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        discountTypes: [
          {name: "%", value: 0},
          {name: "$", value: 1}
        ],

        invoice: {paymentType: 'full', deliveryInfo: {address: {line1: "", line2: "", city: "", country: ""}}, metadata: {grandTotal:0}, discount: 0},
        // customers: [],
        customerDisabledCriteria: false,
        productSearchResults: [],
        productSearchTerm: '',
        customerSearchTerm: '',
        userSearchTerm: '',
        noSearchResults: false,
        taxRate: 0,
        saveInvoiceLoader: false,

        addressDialog: false,
        saveCustomAddressDialog: false,
        customAddressName: "",

        customersDialog: false,
        customerValue: "",
        customerSearchResults: [],
        selectedCustomer: '',
        customerLoading: false,

        usersDialog: false,
        userSearchResults: [],
        selectedUser: '',

        createCustomerDialog: false,
        newCustomer: {addresses: [], suggestedMatches: []},
        nameRules: [
          v => !!v || 'Name is required'
        ],
        discountRules: [
          v => v<(this.invoice.subtotal*(parseFloat(this.getGlobalValue('VEC_MAX_DISCOUNT_PERCENTAGE'))/100)) || `Cannot issue discount more than ${this.getGlobalValue('VEC_MAX_DISCOUNT_PERCENTAGE')}%.`,
        ],
        productScanDialog:{
          isOpen:false,
          loader: false,
          item: "",
        },
        existingData: [],
        listeningForBarcodeScan: false,
        productSearchBarcode: '',
        barcodeScanResult: {},
        discountAuth: '',
        ourAuthRequest: false,
        authorizerState: false,
        searchResultsLoader: false,
        loadingSearchProduct: false,
        numUpdates: 0,
        hidePaymentTypeFull: false,
        creditNotesDialog: false,
        printDialog: {
          isOpen: false,
          quantity: 1,
          jobType: '',
          deliveryNote: '',
          printer: '',
        },
        printPreviewDialog: {
          isOpen: false,
          data: '',
          loading: false,
        },
        singular: "Order",
        singularLower: "order",
        plural: "Orders",
        pluralLower: "orders",
        showDeliveryInfo: true,
        deliverySchedule: {
          tableHeaders:[
            {text: 'DN#', align: 'start', value: 'id'},
            { text: 'Delivery Date', value: 'dueDate' },
            { text: 'Units', value: 'numUnits' },
            { text: 'Status', value: 'status' },
          ],
          data: [],
        },
        paymentTable: {
          headers: [
            {text: 'Date', align: 'start', value: 'createdAt'},
            { text: 'Amount', value: 'amount' },
          ],
          items: [],
        },
        csr: null,
        syncStatus: 1, // 0 = updating; 1 = updateSuccess; 2 = updateFail
        printers: [],
        filteredPrinters: [],
        documentTypes: [],
        createDeliveryDialog: {
          isOpen: false
        },
        addPaymentDialog: {
          isOpen: false,
          selectedType: '',
          creditNotesAvailable: [],
          paymentAmount: null,
          chequeNumber: null,
          creditNotes: [],
          cnMessage: null,
        },
        viewPaymentDialog: {
          isOpen: false,
          paymentToDisplay: {},
        },
        createReturnDialog: {
          isOpen: false,
        },
        productImageDialog: {
          isOpen: false,
          source: '',
          loading: false,
          name:''
        },
        findCustomerByPhoneLoader: false,
        confirmSealDialog: {
          isOpen: false,
          loading: false,
        },
        search: {
          value: "",
          type: "Products",
          types: ["Products", "Customers"],
          dialog: false,
          loading: false,
          results: [],
        },
        chordingDialog:{
          isOpen: false,
          chord: []
        },
        directBigPrintLoading: false,
        directSmallPrintLoading: false,
      }
    },
    created(){
      this.debouncedUpdateInvoice = _.debounce(this.updateInvoice, 500)
    },
    async mounted(){
      try{
        this.initialLoader = true;
        let res = await axios.get(`${this.getEndpoint}/api/orders/${this.$route.params.id}`)
        if(res.data.error) throw res.data.error
        if(res.data.data.Customer && res.data.data.Customer.CreditNotes){
          this.addPaymentDialog.creditNotesAvailable = res.data.data.Customer.CreditNotes
        //{id: 1, type: 0, status: 0, amount: 200, name: 'CN-1 | Normal | Valid | $200'},
          this.addPaymentDialog.creditNotesAvailable = this.addPaymentDialog.creditNotesAvailable.map(x=>{
            return {
              id: x.id,
              status: x.status,
              amount: x.amount,
              expiresAt: x.expiresAt,
              name: `CN-${x.id} -- Value: $${x.amount} -- Expires At: ${this.formatDate(x.expiresAt)} `
            }
          })
        }
        let userPrinters = this.getUser.metadata.printers
        let printers = await axios.get(`${this.getEndpoint}/api/devices/`)
        if(!printers.data.error){
          this.documentTypes = printers.data.data.documentTypes
          let printerDeviceTypeId = (printers.data.data.deviceTypes.filter(x=>x.name.toLowerCase()==='printer')[0]).id //looks at the deviceType object from the response to determine the id of the printers device type
          this.printers = printers.data.data.devices.filter(x=>x.deviceType===printerDeviceTypeId)
          this.printers.forEach(printer=>{
            if(userPrinters && userPrinters.default && userPrinters.default.length>0){
              for(let y in userPrinters.default){
                if(printer.data.jobTypes.includes(y)){
                  if(printer.id==userPrinters.default[y]){
                    printer['isDefault'] = true
                    printer.name = printer.name + ` (Default ${y} printer)`
                  }else printer['isDefault'] = false
                }
              }
            }
          })
          this.filteredPrinters = this.printers
        }else{
          console.log("Error loading printers")
        }
        if(!res.data.data){
          this.snack("Invoice could not be found.")
          await this.$router.go(-1);
        }

        if(!res.data.data.discountAmount) res.data.data.discountAmount = 0
        if(!res.data.data.OrderLineItems) res.data.data.OrderLineItems = []
        if(!res.data.data.metadata) res.data.data.metadata = {creditNotes:[], creditNotesTotal:0, grandTotal:0}
        if(!res.data.data.metadata.grandTotal) res.data.data.metadata.grandTotal = 0

        if(!res.data.data.deliveryInfo) res.data.data.deliveryInfo = {address: {line1: "", line2: "", city: "", country: ""}}

        this.invoice = res.data.data

        if(!this.invoice.metadata.paymentMethod) this.invoice.metadata.paymentMethod = 1

        if(!this.invoice.metadata)
          this.invoice.metadata = {grandTotal:0, csrPaidAt: null, commissionRate: 5}

        if(!this.invoice.metadata.csrPaidAt) this.invoice.metadata.csrPaidAt = null
        if(!this.invoice.metadata.commissionRate) this.invoice.metadata.commissionRate = 5
        
        if(this.invoice.status!==0) this.showDeliveryInfo = false;

        let deliverySchedule = await axios.get(`${this.getEndpoint}/api/deliveries/byOrder/${this.$route.params.id}`)
        if(deliverySchedule.data.error) throw deliverySchedule.data.error

        this.deliverySchedule.data = deliverySchedule.data.data

        this.invoice.subtotal = 0
        this.updateTotals()
        if(this.invoice.customerId){
          let customer = await axios.get(`${this.getEndpoint}/api/customers/${this.invoice.customerId}`)
          if(customer.data.error) throw customer.data.error
          this.selectedCustomer = customer.data.data
          this.checkCustomAddress();
          // this.selectedCustomer.CustomerAdvanceAccounts = this.selectedCustomer.CustomerAdvanceAccounts.filter(x=>!x.closed)
        }
        if(this.invoice.Transactions){
          this.paymentTable.items = this.invoice.Transactions
        }
        // if(this.getItemToAddToNewInvoice.id){
        //   let res = await axios.get(`${this.getEndpoint}/api/products/${this.getItemToAddToNewInvoice.id}`);
        //   let p = res.data.data.data
        //   p.quantityToAdd = this.getItemToAddToNewInvoice.quantity
        //   this.addProductToInvoice(p)
        //   this.clearNewInvoiceItem()
        // }
        // if(this.getItemToAddToExistingInvoice.id && this.getItemToAddToExistingInvoice.invoiceId == this.$route.params.id){
        //   if((this.invoice.orderItems.find(x=>x.id==this.getItemToAddToExistingInvoice.id))==undefined){
        //     let res = await axios.get(`${this.getEndpoint}/api/products/${this.getItemToAddToExistingInvoice.id}`);
        //     let p = res.data.data.data
        //     p.quantityToAdd = this.getItemToAddToExistingInvoice.quantity
        //     this.addProductToInvoice(p)
        //     this.clearExistingInvoiceItem()
        //   }else{
        //     this.snack("Product is already in the order")
        //     this.clearExistingInvoiceItem()
        //   }
        // }

        //contect for signature pad
        // this.ctx  = this.$el.getContext('2d')
        // this.ctx.strokeStyle  =  'black'
        // this.ctx.lineWidth  =  2
        this.activateScanSense()
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
      }
      finally {
        this.initialLoader = false;
      }
    },
    // async created(){
    //   try {

    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
    computed: {
      ...mapGetters([
        'getId',
        'getEndpoint',
        'lookupUsername',
        'isAllowed',
        'getUser',
        'paymentMethods',
        'paymentTypes',
        'lookupPaymentMethods',
        'lookupPaymentType',
        'getGlobalValue',
        'scanBus'
      ]),
      addPaymentDialogDisabledCriteria(){
        //returning true disables the button
        if(!this.addPaymentDialog.selectedType) return true
        if(!this.addPaymentDialog.paymentAmount) return true
        if(this.addPaymentDialog.paymentAmount<1) return true
        if(this.addPaymentDialog.paymentAmount>Math.abs(this.orderBalance)) return true
        if(this.lookupPaymentType(this.addPaymentDialog.selectedType)=="Cheque" && !this.addPaymentDialog.chequeNumber) return true
        if(this.lookupPaymentType(this.addPaymentDialog.selectedType)=="Credit Note" && this.addPaymentDialog.creditNotes.length==0) return true
        if(this.lookupPaymentType(this.addPaymentDialog.selectedType)=="Credit Note"){
          let y = this.addPaymentDialog.creditNotesAvailable.filter(x=>this.addPaymentDialog.creditNotes.includes(x.id)).reduce((acc,x)=>acc+parseFloat(x.amount),0)
          if(this.addPaymentDialog.paymentAmount > y) return true
        }
        return false
      },
      orderBalance(){
        return this.paymentTable.items&&this.paymentTable.items.length>0?parseFloat(this.paymentTable.items.reduce((acc,x)=>acc+parseFloat(x.amount),0)-parseFloat(this.invoice.metadata.grandTotal)):(0-parseFloat(this.invoice.metadata.grandTotal))
      },
      discountOk: function(){
        if(!this.discountApplied && this.invoice.discountAmount) return false
        return true
      },
      discountApplied(){
        if(this.invoice.discountAmount && this.invoice.discountAmount > 0)
          return true
        return false
      },
      modifyDisabledCriteria(){
        return parseInt(this.invoice.status) !== 0
      },
      computedSubtotal(){
        if(this.invoice?.OrderLineItems?.length>0)
          return this.invoice.OrderLineItems.reduce((acc,x)=>acc+(parseFloat(x.unitPrice)*parseInt(x.quantity)),0)
        return 0
      },
      computedGrandTotal(){
        if(this.invoice?.OrderLineItems?.length>0){
          let z = this.computedSubtotal - this.invoice?.discountAmount + this.invoice?.deliveryInfo?.cost
          return z
        }
        return 0
      },
    },
    methods: {
      ...mapMutations([
        'updateWorkingId',
        'deleteWorkingId',
        'lockGlobalQueryBc',
        'unlockGlobalQueryBc',
        'openSIDAuthorizer',
        'closeSIDAuthorizer',
        'clearNewInvoiceItem',
        'clearExistingInvoiceItem',
        'setScanBus',
        'resetScanBus',
      ]),
      canProductSell(item){
        if(!item.manageStock) return true;
        return !((item.ProductLocationJoins.find(x => x.userBranch===true)?item.ProductLocationJoins.find(x => x.userBranch===true).available:0)<1)
      },
      async findCustomerByPhone(){
        try {
          if(!this.newCustomer?.phone){
            this.newCustomer.suggestedMatches = []
            return
          }
          if(this.newCustomer?.phone?.length < 3) return
          this.findCustomerByPhoneLoader = true
          let res = await axios.get(`${this.getEndpoint}/api/customers/byPhone/${this.newCustomer.phone}`)
          this.newCustomer.suggestedMatches = res.data.data
          this.findCustomerByPhoneLoader = false
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
          this.findCustomerByPhoneLoader = false
        }
      },
      createDeliveryA(){
        this.createDeliveryDialog.isOpen = true
      },
      async createDeliveryB(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/deliveries`, {orderId: this.$route.params.id, createdBy: this.getId})
          if(res.data.error) throw res.data.error
          this.createDeliveryDialog.isOpen = false
          await this.$router.push({ path: `/deliveries/view/${res.data.data.id}`})
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      cancelCreateDelivery(){
        this.createDeliveryDialog.isOpen = false
      },
      filterPrinters(){
        this.filteredPrinters = this.printers.filter(x=>x.data.jobTypes.includes(this.cloudPrintDialog.jobType)).sort((a,b)=>b.isDefault-a.isDefault)
        this.cloudPrintDialog.printer = this.filteredPrinters.filter(x=>x.id==this.getUser.metadata.printers.default[this.cloudPrintDialog.jobType])[0]
      },
      removeCustomerFromInvoice(){
        if(this.invoice.Customer) delete this.invoice.Customer
        this.invoice.customerId = null
        this.selectedCustomer = null;
        this.customerSearchTerm = ''
        this.checkCustomAddress();
        this.invoice.deliveryInfo.address = {line1: "", line2: "", city: "", country: ""}
        this.updateInvoice()
      },
      customerSelected(){
        console.log(this.invoice)
      },
      async searchProduct(){
        try {
          if(this.productSearchTerm){
            this.loadingSearchProduct = true;
            let res = await axios.get(`${this.getEndpoint}/api/products/searchByAll?val=${encodeURIComponent(this.productSearchTerm)}`);
            if(res.data.error) throw res.data.error
            this.productSearchResults = res.data.data
            if(this.productSearchResults.length == 0) this.noSearchResults = true
            else this.noSearchResults = false
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }finally {
          this.loadingSearchProduct = false;
        }
      },
      clearProductSearchResults(){
        this.productSearchResults = []
        this.productSearchTerm = ""
      },
      watchInput(){
        if(this.productSearchTerm.length == 0) this.noSearchResults = false
      },
      async fetchCsr(){
        try {
          if(!this.invoice.authorizations.seal.authBy) throw "No CSR Associated with Order."
          let csr = await axios.get(`${this.getEndpoint}/api/users/forOrder/${this.invoice.authorizations.seal.authBy}`)
          if(csr.data.error) throw csr.data.error
          this.csrUser = csr.data.data
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      async removeProduct(e){
        try {
          this.loader = true
          this.syncStatus = 0
          if(!e.pending){
            let res = await axios.delete(`${this.getEndpoint}/api/orders/lineItem/${e.id}`)
            if(res.data.error) throw res.data.error
          }
          let i = (this.invoice.OrderLineItems.findIndex(x=>x.id===e.id))
          this.invoice.OrderLineItems.splice(i,1)
          this.resetDiscount()
          await this.updateInvoice()
        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.loader = false
          this.syncStatus = 1
        }
      },
      addAdhocLineItem(){
        try{
          if(this.invoice.OrderLineItems.find(x => x.productId==='adhoc')) throw {msg: "Please save/remove pending line items before creating another."};
          let li = {
            id: 'adhoc',
            orderId: this.invoice.id,
            productId: 'adhoc',
            productName: '',
            sku: '',
            quantity: 1,
            unitPrice: 0,
            discountValue: 0,
            discountType: 0,
            metadata: {
              serials: [],
              lineTotal: 0,
              commission: {type: 0, value: 0},
            },
            pending: true,
            loading: false
          }
          this.invoice.OrderLineItems.push(li);
        }
        catch (error) {
          console.log(error)
          this.snack(error.msg)
        }
      },
      async completeAdhocItem(i){
        try{
          this.invoice.OrderLineItems[i].loading = true;
          this.invoice.OrderLineItems[i].pending=false;
          console.log(this.invoice.OrderLineItems[i], "aaaa")
          await this.updateLineItem(this.invoice.OrderLineItems[i])
          console.log(this.invoice.OrderLineItems[i], "bbbb")
        }
        catch (error) {
          console.log(error)
          this.snack(error.msg)
        }
        finally {
          this.invoice.OrderLineItems[i].loading = false;
        }
      },
      async addProductToInvoice(p){
        try {
          let found = this.invoice.OrderLineItems.find(x=>x.productId==p.id);
           // format the product here
          if(!found){
            this.syncStatus = 0
            this.loader = true
            let tempLineItem = {
              orderId: this.invoice.id,
              productId: p.id,
              productName: (p.Brand?p.Brand.name+' ':'')+p.name,
              sku: p.sku,
              quantity: 1,
              unitPrice: p.regularPrice,
              discountValue: 0,
              discountType: 0,
              metadata: {
                serials: [],
                lineTotal: p.quantityToAdd*(p.regularPrice),
                commission: {type: 0, value: 0},
              }
            }
            let res = await axios.post(`${this.getEndpoint}/api/orders/lineItem/${this.$route.params.id}`, tempLineItem)
            if(res.data.error) throw res.data.error
            this.invoice.OrderLineItems.push(res.data.data)
            this.resetDiscount()
            this.updateTotals()
          }else{
            if(!p.manageStock){
              found.quantity++;
              return;
            }
            this.snack("Item already in invoice.")
          }
        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.syncStatus = 1
          this.loader = false
        }
      },
      checkAndUpdateLines(){
        this.invoice.OrderLineItems.forEach(p=>{
          if(p.quantity < 1) p.quantity = 0
          if(p.discountValue < 0) p.discountValue = 0
          if(p.discountType==0 && p.discountValue > 20){
            p.discountValue = 0
            this.snack("Discount Value Reset. It cannot exceed 20%.")
          }
          if(p.metadata?.commission?.value < 0) p.metadata.commission.value = 0
          if(parseInt(p.metadata?.commission?.value) > 100) p.metadata.commission.value = 0

          let discountVal = 0;
          if(p.discountType===1) discountVal = p.discountValue
          else discountVal = (p.unitPrice*(p.discountValue/100))
          p.metadata.lineTotal = p.quantity*(p.unitPrice-discountVal)
        })
      },
      async updateLineItem(item){
        try {
          if(item.pending) return;
          if(!item.quantity) item.quantity = 1
          this.syncStatus = 0
          this.loader = true
          let res = await axios.put(`${this.getEndpoint}/api/orders/lineItem/${item.id}`, item)
          if(res.data.error) throw res.data.error
          if(!res.data.success) this.snack("Insufficient quantity in stock.")

          let rowId = item.id==='adhoc'?'adhoc':res.data.data.id;
          let ix = this.invoice.OrderLineItems.findIndex(x=>x.id==rowId)
          ix==-1?null:this.invoice.OrderLineItems.splice(ix, 1, res.data.data)
          this.resetDiscount()
          this.updateTotals()
          this.$forceUpdate()
        } catch (error) {
          console.log(error)
          this.snack(error.msg)
        } finally {
          this.syncStatus = 1
          this.loader = false
        }
      },
      updateLineDiscount(p){
        console.log("Update Line Discount")
        if(p.discountValue < 0) p.discountValue = 0
        if(p.discountType==0 && p.discountValue > 20) p.discountValue = 0
        this.updateLineTotal(p)
      },
      updateLineCommission(p){
        console.log("Update Line Commission")
        if(p.metadata.commission.value < 0) p.metadata.commission.value = 0
        if(parseInt(p.metadata.commission.value) > 100) p.metadata.commission.value = 0
        console.log("He")
        this.updateLineTotal(p)
      },
      updateTotals(){
        this.checkAndUpdateLines()
        let discountToApply = 0
        if(this.discountApplied)
          discountToApply = this.invoice.discountAmount
        if(!(this.invoice.deliveryInfo && this.invoice.deliveryInfo.cost))
          this.invoice.deliveryInfo.cost = 0
        this.invoice.deliveryInfo.cost = Math.abs(parseFloat(this.invoice.deliveryInfo.cost))
        this.invoice.subtotal = this.invoice.OrderLineItems.reduce((a,x)=>a+x.metadata.lineTotal,0)
        this.invoice.metadata.grandTotal = (this.invoice.subtotal - discountToApply) + this.invoice.deliveryInfo.cost
        // console.log(this.invoice.metadata.grandTotal)
        this.$forceUpdate()
      },
      formatPrice(value){
        if(value===null) return "";
        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        return formatter.format(parseFloat(value));
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      resetExisting(){
        this.existingData = [];
      },
      async searchUser(){
        try {
          if(this.userSearchTerm){
            this.searchResultsLoader = true
            let res = await axios.get(`${this.getEndpoint}/api/users/search?val=${encodeURIComponent(this.userSearchTerm)}`);
            if(res.data.error) throw res.data.error
            this.userSearchResults = res.data.data
            if(this.userSearchResults && this.userSearchResults.length == 0) this.noSearchResults = true
            else this.noSearchResults = false
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }finally{
          this.searchResultsLoader = false
        }
      },
      async searchCustomer(){
        try {
          if(this.customerSearchTerm){
            this.searchResultsLoader = true
            let res = await axios.get(`${this.getEndpoint}/api/customers/search?val=${encodeURIComponent(this.customerSearchTerm)}`);
            if(res.data.error) throw res.data.error
            this.customerSearchResults = res.data.data
            if(this.customerSearchResults && this.customerSearchResults.length == 0) this.noSearchResults = true
            else this.noSearchResults = false
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }finally{
          this.searchResultsLoader = false
        }
      },
      async updateInvoice(){
        try {
          if(this.invoice&&this.invoice.deliveryInfo&&!this.invoice.deliveryInfo.cost) this.invoice.deliveryInfo.cost = 0
          this.syncStatus = 0
          this.updateTotals()
          if(this.modifyDisabledCriteria) return // very important it must be at the top
          if(this.initialLoader) return 
          if(this.invoice.status > 0){
            throw "❌ Order already Sealed."
          }
          let res = await axios.put(`${this.getEndpoint}/api/orders/${this.$route.params.id}`, this.invoice)
          if(res.data.error) throw res.data.error
          this.draftWasSaved = true
          this.updateTotals()
          this.syncStatus = 1
        } catch (error) {
          this.syncStatus = 2
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.loader = false
        }
      },
      resetDiscount(){
        this.invoice.discountAmount = 0
      },
      updateOrderDiscount(){
        if(this.invoice?.metadata?.grandTotal<1){
          this.invoice.discountAmount = 0
          this.snack(`The Discount was not applied. Grand Total cannot be 0.`)
        }
        if(!this.invoice?.discountAmount) this.invoice.discountAmount = 0
        if(this.invoice?.discountAmount < this.invoice?.subtotal*(parseFloat(this.getGlobalValue('VEC_MAX_DISCOUNT_PERCENTAGE'))/100)){
          this.updateInvoice()
        }else{
          this.snack(`The Discount was not applied. Discount value > ${this.getGlobalValue('VEC_MAX_DISCOUNT_PERCENTAGE')}%.`)
        }
      },
      closeUsersDialog(){
        this.usersDialog = false;
        this.userSearchTerm = ''
        this.userSearchResults = [];
        this.$forceUpdate()
      },
      async setUser(user){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/users/${user.id}`)
          if(res.data.error) throw res.data.error
          this.invoice.csrUser = res.data.data
          this.invoice.csrId = user.id
          await this.updateInvoice();
          this.closeUsersDialog();
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      async removeCsrUser(){
        try {
          this.invoice.csrUser = null
          this.invoice.csrId = null
          await this.updateInvoice();
          this.closeUsersDialog();
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      closeCustomersDialog(){
        this.customersDialog = false;
        this.customerSearchTerm = ''
        this.customerSearchResults = [];
        this.$forceUpdate()
      },
      async setCustomerAndClose(customer){
        await this.setCustomer(customer)
        this.createCustomerDialog = false
      },
      async setCustomer(customer){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/customers/${customer.id}`)
          if(res.data.error) throw res.data.error
          this.invoice.customerId = customer.id;
          this.selectedCustomer = res.data.data
          this.closeCustomersDialog();
          this.invoice.deliveryInfo.address = {line1: "", line2: "", city: "", country: ""}
          this.checkCustomAddress();
          this.updateInvoice()
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      closeCreateCustomer(){
        this.createCustomerDialog = false;
        this.newCustomer = {addresses: [], suggestedMatches: []};
        this.customerLoading = false;
        this.existingData = [];
      },
      async createCustomer(){
        try{
          this.customerLoading = true;
          let res = await axios.post(`${this.getEndpoint}/api/customers/createAdHoc`, this.newCustomer)
          if(res.data.error) throw res.data.error
          this.snack("Customer Created.")
          this.invoice.customerId = res.data.data.id
          this.selectedCustomer = res.data.data
          this.closeCreateCustomer();
          this.updateInvoice()
        }
        catch (error){
          console.error(error)
          this.snack(error.msg || error, "error")
        }
        finally {
          this.customerLoading = false;
        }
      },
      setAddress(addr){
        this.invoice.deliveryInfo.address = {line1: addr.line1||"", line2: addr.line2||"", city: addr.city||"", country: addr.country||"", name: addr.name};
        this.invoice.deliveryInfo.customAddress = false;
        this.updateInvoice()
        this.closeAddressDialog();
      },
      closeAddressDialog(){
        this.addressDialog = false;
      },
      async saveCustomAddress(){
        try{
          if(this.customAddressName.length===0) throw "A name is required."
          let addr = {...this.invoice.deliveryInfo.address};
          addr.name = this.customAddressName;
          let res = await axios.put(`${this.getEndpoint}/api/customers/addAddress/${this.selectedCustomer.id}`, addr)
          if(res.data.error) throw res.data.error
          this.snack("Address added successfully!");
          this.invoice.deliveryInfo.customAddress = false;
          this.selectedCustomer.addresses.push({line1: addr.line1, line2: addr.line2, city: addr.city, country: addr.country, name: addr.name});
          this.closeCustomAddressDialog();
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      checkCustomAddress(){
        let val = false;

        if(this.invoice.deliveryInfo.address.line1==="" &&
            this.invoice.deliveryInfo.address.line2==="" &&
            this.invoice.deliveryInfo.address.city==="" &&
            this.invoice.deliveryInfo.address.country===""){
          this.invoice.deliveryInfo.customAddress = val;
          return;
        }

        val = true;

        if(this.selectedCustomer && this.selectedCustomer.addresses){
          for(let addr of this.selectedCustomer.addresses){
            if((this.invoice.deliveryInfo.address.line1?this.invoice.deliveryInfo.address.line1===addr.line1:true) &&
                (this.invoice.deliveryInfo.address.line2?this.invoice.deliveryInfo.address.line2===addr.line2:true) &&
                (this.invoice.deliveryInfo.address.city?this.invoice.deliveryInfo.address.city===addr.city:true) &&
                (this.invoice.deliveryInfo.address.country?this.invoice.deliveryInfo.address.country===addr.country:true)){
              val = false;
            }
          }
        }
        else{
          val = false;
        }

        this.invoice.deliveryInfo.customAddress = val;
        this.$forceUpdate();
      },
      closeCustomAddressDialog(){
        this.customAddressName = "";
        this.saveCustomAddressDialog = false;
      },
      listenForBarcodes(){
        this.lockGlobalQueryBc()
        console.log("Listening for Barcode Scan")
        this.listeningForBarcodeScan = true
        this.$refs.barcodeTerm.focus()
      },
      cancelListenForBarcodes(){
        this.unlockGlobalQueryBc()
        this.listeningForBarcodeScan = false
        this.$refs.barcodeTerm.blur()
        this.productSearchBarcode = ''
      },
      barcodeSearchProduct(){
        this.queryDialogSearch()
      },
      async queryDialogSearch(){
        try {
          let pId = (this.productSearchBarcode.slice(0,5)).replace(/^0+/, '');
          this.queryDialogText = `ID Scanned: ${this.fromBase62(pId)}`
          let res = await axios.get(`${this.getEndpoint}/api/products/searchByIdWithVariations/${this.fromBase62(pId)}`);
          if(res.data.error) throw res.data.error

          if(res.data.data===null){
            this.productSearchBarcode = ''
            throw "Invalid barcode value. Try again.";

          }else{
            this.barcodeScanResult = res.data.data
            this.cancelListenForBarcodes()

            this.addProduct(this.barcodeScanResult)
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      toBase62(n) {
        if (n === 0) {
          return '0';
        }
        var digits = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var result = '';
        while (n > 0) {
          result = digits[n % digits.length] + result;
          n = parseInt(n / digits.length, 10);
        }

        return result;
      },
      fromBase62(s) {
        var digits = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var result = 0;
        for (var i=0 ; i<s.length ; i++) {
          var p = digits.indexOf(s[i]);
          if (p < 0) {
            return NaN;
          }
          result += p * Math.pow(digits.length, s.length - i - 1);
        }
        return result;
      },
      attemptConfirmSeal(){
        this.confirmSealDialog.isOpen = true
      },
      async sealOrder(){
        try {
            let res = await axios.post(`${this.getEndpoint}/api/orders/changeStatus/sealed/${this.$route.params.id}`)
            if(res.data.error) throw res.data.error
            this.invoice.status = 1
            this.confirmSealDialog = {isOpen: false, loading: false}
            this.addPaymentDialog.isOpen = true;
        } catch (error) {
          console.log(error)
          this.snack(error)
        }
      },
      endShortcut(){
        this.clearScanString()
        this.chordingDialog.chord = []
        this.chordingDialog.isOpen = false
      },
      async handleChord(code){
        try {
          let key = code.key.toLowerCase()
          if(key !== "arrowdown" && key !== "arrowup"&&key !== "arrowright"&&key !== "arrowleft")
            this.chordingDialog.chord.push(key)
          if(this.chordingDialog.chord[this.chordingDialog.chord.length-1]==='x'){
            this.chordingDialog.chord = []
            this.search.results= []
          }
          if(this.chordingDialog.chord[this.chordingDialog.chord.length-1]==='backspace'){
            this.chordingDialog.chord.pop()
            this.chordingDialog.chord.pop()
            this.search.results = []
          }
          let str = this.chordingDialog.chord.join().replace(",","")
          if(str =='on' || str =='n' ){
            console.log("Create New Order")
            this.attemptCreateInvoice()
            this.endShortcut()         
          }
          if(str ==='ol'){
            console.log("List Orders")
            await this.$router.push({path: `/orders`})
            this.endShortcut()              
          }
          if(str[0]=='o'&&str[1]=='v'&&str.length>2&&this.chordingDialog.chord[this.chordingDialog.chord.length-1] == 'enter'){
            let orderId = this.chordingDialog.chord.slice(2,this.chordingDialog.chord.length-1)
            orderId = orderId.join("").replace(/,/g,"")
            console.log(orderId)
            await this.$router.push({path: `/orders/view/${orderId}`})
            this.endShortcut()
          }
          if(str ==='b'){
            await this.$router.push({path: `/orders`})
            this.endShortcut()              
          }
          if(str ==='h'){
            await this.$router.push({path: `/`})
            this.endShortcut()              
          }
          if(str[0]=='a'){
            let prod = this.chordingDialog.chord.slice(2)
            prod = prod.join("").replace(/,/g,"")
            this.search.results = []
            this.search.type = 'Products'
            this.search.value = prod
            this.querySearch()
          }
          if(str[0]=='s'&&str[1]=='c'&&str.length>2){
            let prod = this.chordingDialog.chord.slice(2)
            prod = prod.join("").replace(/,/g,"")
            this.search.results = []
            this.search.type = 'Customers'
            this.search.value = prod
            this.querySearch()
          }
        } catch (error) {
          console.log(error)
          this.snack(error)
        }
      },
      async attemptCreateInvoice(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/orders`, {createdBy: this.getId})
          if(res.data.error) throw res.data.error
          console.log(res.data)
          await this.$router.push({path: `/orders/view/${res.data.data.id}`})
        } catch (error) {
          console.log(error)
        }
      },
      async authAttempt(credentials, type){
        try {
          this.loader = true;
          if(!credentials) throw "No Credentials Supplied"
          let x = {}
          switch(type){
            case "seal":
              x = await axios.post(`${this.getEndpoint}/api/orders/changeStatus/sealed/${this.$route.params.id}`, credentials)
              if(x.data.error) throw x.data.error
              this.invoice.status = 1
              this.addPaymentDialog.isOpen = true;
              break
            case "void":
              x = await axios.post(`${this.getEndpoint}/api/orders/changeStatus/voided/${this.$route.params.id}`, credentials)
              if(x.data.error) throw x.data.error
              this.invoice.status = -1;
              this.invoice.voidedAt = x.data.data.voidedAt
              this.invoice.voidedBy = x.data.data.voidedBy
              break
            case "discount":
              x = await axios.post(`${this.getEndpoint}/api/orders/authDiscount/${this.$route.params.id}`, credentials)
              if(x.data.error) throw x.data.error
              this.invoice.discountBy = x.data.data.discountBy
              this.$forceUpdate()
              break
            default:
              throw "No Type Supplied to Handler"
          }
          this.snack("✅ Success.")
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.loader = false;
        }
      },
      openCloudPrintDialog(){
        this.cloudPrintDialog.isOpen = true
      },
      async directPrint(obj){
        try {
          this.directBigPrintLoading = true
          let x = await axios.post(`${this.getEndpoint}/api/print/preview/invoice/${obj.id}`)
          if(x.data.error) throw x.data.error
          let printWindow = open("","Printing")
          printWindow.document.write("")
          printWindow.document.write(x.data.data.job.htmlToPrint)
          printWindow.setTimeout(()=>{
            printWindow.print()
            printWindow.document.write("")
          },500)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.directBigPrintLoading = false
        }
      },      
      async printPreview(type, metadata){
        try {
          this.printPreviewDialog.loading = true
          let x = await axios.post(`${this.getEndpoint}/api/print/preview/${type}/${metadata.id}`)
          if(x.data.error) throw x.data.error
          this.printPreviewDialog.data = x.data.data
          this.printPreviewDialog.loading = false
          this.printPreviewDialog.isOpen = true
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      closePrintPreviewDialog(){
        this.printPreviewDialog.loading = false
          this.printPreviewDialog.data = ''
          this.printPreviewDialog.isOpen = false
      },
      closePrintDialog(){
        this.cloudPrintDialog = {
          isOpen: false,
          quantity: 1,
          jobType: '',
          deliveryNote: '',
          printer: ''
        }
      },
      async attemptPrint(e){
        try {
            if(!e.pin&&!e.vsidToken) throw "Error Getting Credentials"
            e.metadata = this.cloudPrintDialog
            let documentId = (this.cloudPrintDialog.jobType === 'invoice'||this.cloudPrintDialog.jobType === 'jobTicket'?this.$route.params.id:this.cloudPrintDialog.deliveryNote)
            let res = await axios.post(`${this.getEndpoint}/api/print/${this.cloudPrintDialog.jobType}/${documentId}`, e)
            if(res.data.error) throw res.data.error
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.closePrintDialog();
        }
      },
      invoiceOK: function(){
        let verbose = false;
        if(verbose) console.log(this.invoice);
        //payment type ok?
        if(this.invoice && !this.invoice.metadata.paymentMethod) return false
        // if(this.invoice && this.invoice.metadata.paymentMethod == 'onAccount'){
        //   if(this.invoice.metadata && !this.invoice.metadata.arDownpayment) return false
        // }
        if(verbose) console.log("payment ok.")
        // customer ok?
        if(!this.invoice.customerId) return false
        if(verbose) console.log("customer ok.")
        //products ok?
        if(this.invoice.OrderLineItems && this.invoice.OrderLineItems.length == 0) return false
        for(let oli of this.invoice.OrderLineItems){
          if(oli.pending || oli.id==='adhoc' || oli.productId==='adhoc') return false;
        }
        if(this.invoice.grandTotal == 0) return false
        if(verbose) console.log("products ok.")
        //discountOk?
        // if(!this.discountOk) return false
        // if(verbose) console.log("discount ok.")
        //delivery info ok?
        if(!this.invoice.deliveryInfo.deliveryType) return false
        if(this.invoice.deliveryInfo.deliveryType === 'delivery'){
          if(!this.invoice.deliveryInfo.address) return false
        }
        if(verbose) console.log("delivery ok.")
        return true
      },
      async addSerialToItem(item){
        try {
          let res = await axios.put(`${this.getEndpoint}/api/orders/addSerialToInvoiceItem/${this.$route.params.id}/${item.id}`, {serial: item.serialField})
          if(res.data.error) throw res.data.error

          console.log(res.data.data)
          this.snack("Serial Added")
          item.serials.push(res.data.data)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          item.serialField = ''
        }
      },
      updateLineItemDiscountType(i){
        let obj = {
          ...this.invoice.OrderLineItems[i],
          discountValue: 0,
          discountType: this.invoice.OrderLineItems[i].discountType===0?1:0
        }
        this.updateTotals()
        this.$set(this.invoice.OrderLineItems, i, obj)
        this.$forceUpdate();
      },
      updateLineItemCommissionType(i){
        let obj = {
          ...this.invoice.OrderLineItems[i],
          metadata: {
            ...this.invoice.OrderLineItems[i].metadata,
            commission: {
              ...this.invoice.OrderLineItems[i].commission,
              type:this.invoice.OrderLineItems[i].commission.type===0?1:0,
              value: 0
            }
          }
        }

        this.$set(this.invoice.OrderLineItems, i, obj)
        this.$forceUpdate();
      },
      // openCreditNotesDialog(){
      //   this.creditNotesDialog = true;
      // },
      // closeCreditNotesDialog(){
      //   this.creditNotesDialog = false;
      // },
      // addCreditNote(note){
      //   console.log(note);
      //   this.calcCreditNotesTotal()
      // },
      // removeCreditNote(i){
      //   this.invoice.metadata.creditNotes.splice(i, 1);
      //   this.calcCreditNotesTotal()
      // },
      // calcCreditNotesTotal(){
      //   if(this.invoice.metadata.creditNotes.length===0){
      //     this.invoice.metadata.creditNotesTotal = 0;
      //     return;
      //   }
      //   let total = 0;
      //   this.invoice.metadata.creditNotes.forEach(note => {
      //     total+=note.amount;
      //   });
      //
      //   this.invoice.metadata.creditNotesTotal = total;
      // },
      rowClick(row){
        this.$router.push({path: `/deliveries/view/${row.id}`})
      },
      parseDeliveryStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
        else if(id===2) return "Delivered"
      },
      parseOrderStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
        else if(id===2) return "Admin Sealed"
        else if(id===3) return "Delivery Scheduled"
        else if(id===4) return "Pending Reschedule"
        else if(id===5) return "Out For Delivery"
        else if(id===6) return "Delivered"
      },
      formatDate(d, type="short"){
        if(!d) return "None";
        if(type=="short")
          return (new Date(d)).toLocaleDateString('en-GB')
        if(type=="long")
          return (new Date(d)).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
      },
      applyDefaultCommissionToAll(){
        this.invoice.OrderLineItems.forEach(item=>{item.metadata.commission.type = 0; item.metadata.commission.value = this.csrUser.metadata.commission})
        this.snack("✅ Line commissions updated to CSR default of "+ this.csrUser.metadata.commission + "%.")
      },
      clearScanString(){
        this.scanString = ''
        this.scanInProgress = false
        console.log("🔁 Scan String Cleared")
      },
      clearScanStringTimeout(){
        this.timeout = setTimeout(this.clearScanString,500)
      },
      cancelClearScanStringTimeout(){
        clearTimeout(this.timeout)
      },
      activateScanSense(){
        console.log('ORDER PAGE: ✅ Scan Sense Active.')
        window.addEventListener('keydown', (e)=>{ // keys will always be pressed,
          if(this.scanBus.locked&&this.scanBus.type=="order"){ 
            console.log("ORDER ",e)
            this.cancelClearScanStringTimeout()
            this.clearScanStringTimeout()
            if(this.chordingDialog.isOpen){
              this.cancelClearScanStringTimeout()
              if(e.key==='/'){
                this.endShortcut()
              }else{
                this.handleChord(e)
              }
            }else{
              if(e.key==='/'){
                  this.cancelClearScanStringTimeout()
                  this.chordingDialog.isOpen = true
              }else{
                if(e.key==='Enter'){
                  let validatedType = scanSystem.validateType(this.scanString)
                  if(validatedType){
                    this.scanInProgress = true
                    this.handleScan(this.scanBus.type)
                    this.clearScanString
                  }else{
                    this.clearScanString
                    this.resetScanBus()
                  }
                }else{
                  this.scanInProgress = false
                  if(!this.scanString) this.scanString = ""
                  this.scanString = this.scanString + e.key
                }
              }
            }
          }
        })  
      },
      async handleScan(type){
        try {
          let s = this.scanString.replace(/\\\/\/\\=/,'')
          let pId = (s.replace(`${type}-`, ''));
          let p;
          pId
          let prod = null
          switch(type){
            case 'INV':
              console.log("Invoice Scanned: ", s)
              p = `/orders/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              break
            case 'VSID':
              // this.setScanBus({username: this.lookupUsername(pId.split("-")[0]), uId: pId.split("-")[0]})
              // console.log(this.lookupUsername(pId.split("-")[0]))
              p = '/users/view/'+pId.split("-")[0]
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              break
            case 'JT':
              console.log("Job Ticket Scanned: ", s)
              p = `/orders/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              break
            case 'DN':
              console.log("Delivery Note Scanned: ", s)
              p = `/deliveries/view/${s.split('-')[1]}`
              if(this.$route.path!==p)
                await this.$router.push({path: p})
              break
            case 'PL':
              console.log("Product Scanned: ", s) //this
              this.openProductScanDialog()
              prod = await this.lookupProduct((s.split('-')[1]), "ID")
              if(!prod) throw "Product Not In VIMS DB."
              this.productScanDialog.item = prod.data
              break
            case 'EXT':
              console.log("Invoice External Product Scanned: ", s)
              this.openProductScanDialog()
              prod = await this.lookupProduct(s, "SKU")
              if(!prod) throw "External Product Not In VIMS DB."
              this.productScanDialog.item = prod
              break
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.scanInProgress = false
        }
      },
      openProductScanDialog(){
        this.productScanDialog.isOpen = true
        this.productScanDialog.loader = true
      },
      closeProductScanDialog(){
        this.productScanDialog.isOpen = false,
        this.productScanDialog.loader = false
        this.productScanDialog.item = ''
      },
      async lookupProduct(s, type){
        try {
          let res;
          if(type === "SKU")
            res = await axios.get(`${this.getEndpoint}/api/products/bySKU/${s}`)
          if(type === "PL")
            res = await axios.get(`${this.getEndpoint}/api/products/${s}`)
          if(res.data.error) throw res.data.error
          if(!res.data.data) throw 'External barcode is not in VIMS DB.'
          return res.data.data
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.productScanDialog.loader = false
        }
      },
      openAddPayment(){
        this.addPaymentDialog.isOpen = true
      },
      closeAddPaymentDialog(){
        this.addPaymentDialog.isOpen = false
        this.addPaymentDialog.selectedType = null
        this.addPaymentDialog.paymentAmount = null
        this.addPaymentDialog.chequeNumber = null
        this.addPaymentDialog.creditNotes = []  
        this.addPaymentDialog.cnMessage = null
      },
      async addPayment(){
        try {
          let obj = {
            orderId: this.$route.params.id,
            amount: this.addPaymentDialog.paymentAmount,
            paymentType: this.addPaymentDialog.selectedType,
            metadata: {
              chequeNumber: this.addPaymentDialog.chequeNumber,
              creditNotes: this.addPaymentDialog.creditNotes
            }
          }
          console.log(obj)
          let res = await axios.post(`${this.getEndpoint}/api/orders/addPayment/${this.$route.params.id}`, obj)
          if(res.data.error) throw res.data.error
          this.snack("Order Added")
          this.closeAddPaymentDialog()
          this.snack("Payment Successfully Added.")
          this.paymentTable.items.push(res.data.data)
        } catch (error) {
          this.snack(error)
          console.log(error)
        }
      },
      paymentTableRowClick(e){
        this.viewPaymentDialog.paymentToDisplay = e
        this.viewPaymentDialog.isOpen = true
        console.log(this.viewPaymentDialog)
      },
      closeViewPaymentDialog(){
        this.viewPaymentDialog.isOpen = false
        this.viewPaymentDialog.paymentToDisplay = {}
      },
      runPaymentDialogValidations(){
        this.addPaymentDialog.cnMessage = null
        if(this.addPaymentDialog.selectedType&&this.addPaymentDialog.paymentAmount){
          console.log(this.lookupPaymentType(this.addPaymentDialog.selectedType))
          if(this.lookupPaymentType(this.addPaymentDialog.selectedType)=='Credit Note'){
            if(this.addPaymentDialog.creditNotes.length>0){
              let y = this.addPaymentDialog.creditNotesAvailable.filter(x=>this.addPaymentDialog.creditNotes.includes(x.id)).reduce((acc,x)=>acc+parseFloat(x.amount),0)
              if(y > this.addPaymentDialog.paymentAmount){
                this.addPaymentDialog.cnMessage = "The Value of the selected CN exceeds the tendered amount. A new CN will be created with the Customer's Balance."
              }else if(this.addPaymentDialog.paymentAmount > y){
                this.addPaymentDialog.cnMessage = "The Value of the tendered exceeds the CN amounts."
              }else{
                this.addPaymentDialog.cnMessage = null
              }
            }
            else{
              this.addPaymentDialog.cnMessage = null  
            }
          }else {
            this.addPaymentDialog.cnMessage = null
            this.addPaymentDialog.creditNotes = []
          }
        }
      },
      async createNewReturn(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/`)
          if(res.data.error) throw res.data.error
          this.createDialog.isOpen = false
          await this.$router.push({ path: `/${this.pluralLower}/create/${res.data.data.id}`})
        } catch (error) {
          console.log(error)
          this.snack(error.msg || error, "error");
         this.createDialog.isOpen = false
        }
      },

      async updateInvoiceDatePaid(){
        try {
          this.syncStatus = 0
          let valToSend = this.invoice.metadata?.csrPaidAt
          if(!this.invoice.metadata?.csrPaidAt) valToSend = -1
          let res = await axios.put(`${this.getEndpoint}/api/orders/commissionInfo/${this.$route.params.id}`, {csrPaidAt: valToSend})
          if(res.data.error) throw res.data.error  
          this.snack('Commission info updated')
          this.syncStatus = 1
        } catch (error) {
          this.syncStatus = 2
          console.log(error)
          this.snack(error.msg || error, "error");
        }
      },
      async updateInvoiceCommissionRate(){
        try {
          this.syncStatus = 0
          let valToSend = this.invoice.metadata?.commissionRate
          if(!this.invoice.metadata?.commissionRate) valToSend = -1
          let res = await axios.put(`${this.getEndpoint}/api/orders/commissionInfo/${this.$route.params.id}`, {commissionRate: valToSend})
          if(res.data.error) throw res.data.error  
          this.snack('Commission info updated')
          this.syncStatus = 1
        } catch (error) {
          this.syncStatus = 0
          console.log(error)
          this.snack(error.msg || error, "error");
        }
      },
      async showLineItemProductImage(item){
        try {
          this.syncStatus = 0
          this.productImageDialog.loading = true
          let res = await axios.get(`${this.getEndpoint}/api/products/featuredImage/${item.productId}`)
          if(res.data.error) throw res.data.error
          if(!res.data.data) throw "No image available."
          this.productImageDialog = {
            isOpen: true,
            source: res.data.data,
            name: item.name
          }
          this.syncStatus = 1
        } catch (error) {
          this.syncStatus = 0
          console.log(error)
          this.snack(error.msg || error, "error");
        } finally {
          this.productImageDialog.loading = false
        }
      },   
      closeProductImageDialog(){
        this.productImageDialog = {
            isOpen: false,
            loading: false,
            source: '',
            name: ''
          }
      },
      openSearchDialog(){
        this.search.dialog=true;
      },
      clearSearch(){
        this.search.value = "";
        this.search.results = [];
      },
      async querySearch(){
        try{
          this.search.loading = true;
          this.search.results = [];

          if(!this.search.value) return;

          let searchData = {
            val: this.search.value
          }

          let uriFields = Object.keys(searchData).map(x => {
            return x + "=" + (searchData[x]!==undefined?encodeURIComponent(searchData[x]):'')
          }).join("&");

          let res;
          let arr = [];
          switch (this.search.type) {
            case "Customers":
              res = await axios.get(`${this.getEndpoint}/api/customers/search?${uriFields}`);
              if(res.data.error) throw res.data.error;
              arr = res.data.data;
              break;
            case "Products":
            default:
              res = await axios.get(`${this.getEndpoint}/api/products/searchByAllVariationsNoLimit?${uriFields}`);
              if(res.data.error) throw res.data.error
              arr = res.data.data.concat(res.data.brandSearch);
          }

          this.search.results = arr;
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
        finally {
          this.search.loading = false;
        }
      }
    }
  }
</script>